import Select from 'react-select';
import { useForm } from '../core/FormProvider';
import { formatMask } from "../../../utils/formatMonetaryToBr";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export const ProductForm = ({ formik }) => {
    const { categories, contractTerms, category, setCategory, contractTerm, setContractTerm, loading, active, setActive } = useForm()

    const navigate = useNavigate();
    const handleCheckboxChangeActive = () => {
        setActive(!active)
        if (active) {
            const req = { active: false }
            Swal.fire({
                title: 'Deseja remover o produto de todas as vendas?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setActive(false)
                    formik.setFieldValue('active', false)
                } else {
                    setActive(true)
                }
            })
        } else {
            formik.setFieldValue('active', !active)
        }
    }
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-body border-top p-9'>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Nome</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Nome do produto'
                                {...formik.getFieldProps('name')}
                                maxLength={64}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors?.name}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Preço</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <input
                                id="price"
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='preço'
                                {...formik.getFieldProps('price')}
                                onChange={(e) => {
                                    formik.setFieldValue('price', formatMask(e.target.value))
                                }}
                            />
                            {formik.touched.price && formik.errors.price ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors?.price}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Preço Pacote</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <input
                                id="packagePrice"
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                placeholder='Preço Pacote'
                                {...formik.getFieldProps('packagePrice')}
                                onChange={(e) => {
                                    formik.setFieldValue('packagePrice', formatMask(e.target.value))
                                }}
                            />
                            {formik.touched.packagePrice && formik.errors.packagePrice ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors?.packagePrice}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Gênero</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <select
                                className='form-select form-select-lg form-select-solid'
                                {...formik.getFieldProps('gender')}
                                onChange={(e) => {
                                    const value = e.target.value
                                    formik.setFieldValue('gender', value)
                                }}
                            >
                                <option value=''>Selecione o Gênero</option>
                                <option value='O'>Masculino e Feminino</option>
                                <option value='M'>Masculino</option>
                                <option value='F'>Feminino</option>
                            </select>
                            {formik.touched.gender && formik.errors.gender && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.gender}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Categoria</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <Select
                                className='react-select-styled react-select-solid'
                                classNamePrefix='react-select'
                                onChange={(option) => {
                                    formik.setFieldValue('category', String(option?.value))
                                    setCategory(option || undefined)
                                }}
                                options={categories}
                                value={category}
                                placeholder='Selecione a Categoria'
                            />
                            {formik.touched.category && formik.errors.category && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.category}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row mb-6' hidden={formik.values.category == '3' ? true : false}>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Tempo de execução (em minutos)</span>
                        </label>
                        <div className='col-lg-8 fv-row' >
                            <input
                                id="sessionTime"
                                type='number'
                                className='form-control form-control-lg form-control-solid'
                                {...formik.getFieldProps('sessionTime')}
                                onChange={(e) => {
                                    formik.setFieldValue('sessionTime', e.target.value)
                                }}
                            />
                            {formik.touched.sessionTime && formik.errors.sessionTime ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors?.sessionTime}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className='row mb-6' hidden={formik.values.category == '3' ? true : false}>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Tempo mínimo para agendamento</span>
                        </label>
                        <div className='col-lg-8 fv-row' >
                            <input
                                id="minDaysBetweenSessions"
                                type='number'
                                className='form-control form-control-lg form-control-solid'
                                {...formik.getFieldProps('minDaysBetweenSessions')}
                                onChange={(e) => {
                                    formik.setFieldValue('minDaysBetweenSessions', e.target.value)
                                }}
                                placeholder='Informe o tempo mínimo para agendamento'
                            />
                            {formik.touched.minDaysBetweenSessions && formik.errors.minDaysBetweenSessions ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors?.minDaysBetweenSessions}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Termo</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <Select
                                className='react-select-styled react-select-solid'
                                classNamePrefix='react-select'
                                onChange={(option) => {
                                    formik.setFieldValue('contractTerm', String(option?.value))
                                    setContractTerm(option || undefined)
                                }}
                                options={contractTerms}
                                value={contractTerm}
                                placeholder='Selecione o Termo'
                            />
                            {formik.touched.contractTerm && formik.errors.contractTerm && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.contractTerm}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>%Desconto máximo</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <input
                                id="discount"
                                type='number'
                                className='form-control form-control-lg form-control-solid'
                                {...formik.getFieldProps('discount')}
                                onChange={(e) => {
                                    formik.setFieldValue('discount', e.target.value)
                                }}
                                placeholder='Informe o desconto para o produto/item em %'
                            />
                            {formik.touched.discount && formik.errors.discount && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.discount}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Ativo</span>
                        </label>
                        <div className='col-lg-8 fv-row'>
                            <div className='col-lg-8 fv-row'>
                                <div className="form-check form-switch form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" id="active" checked={active}
                                        onChange={handleCheckboxChangeActive}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type="reset" className="btn btn-secondary me-2" onClick={() => navigate(-1)}>Cancelar</button>

                    <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
                        {!loading && 'Salvar'}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </form>
    );
};