import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatMask} from '../../../../utils/formatMonetaryToBr'
import {Billing, TBillingInstallment} from '../../core/_models'
import {formatDate} from '../../../../utils/dayJs/format'
import {handlePayment, sendLinkChangeCartReq} from './core/_request'
import Swal from 'sweetalert2'
import {LoadingSpinner} from '../../../../utils/loading/loadingSpinner'
import {cpfMask} from '../../../chooseFranchise/utils/mask'

interface IPaymentRecurrentModal {
  billingInstallment: TBillingInstallment | undefined
  billing: Billing | null
  closeModal: () => void
}

export const PaymentRecurrentModal = ({
  billingInstallment,
  billing,
  closeModal,
}: IPaymentRecurrentModal) => {
  const [loading, setLoading] = useState(false)

  const hadlePaymentCart = async () => {
    Swal.fire({
      title: 'Deseja tentar realizar a cobrança ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true)
          await handlePayment(Number(billing?.recurrencePayment.id), Number(billingInstallment?.id))
          setLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!!!',
            text: 'Cobrança realizada com sucesso!',
            timer: 3000,
          })

          setTimeout(function () {
            window.location.reload()
          }, 2000)
        } catch (error: any) {
          setLoading(false)
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: error.response.data.message,
            timer: 3000,
          })
        }
      }
    })
  }
  return (
    <>
      {loading && <LoadingSpinner />}
      <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
        <div className='row mb-6 mt-4'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-120px'>Preço</th>
                  <th className='min-w-120px'>Forma de pagamento</th>
                  <th className='min-w-120px'>Dt de vencimento</th>
                  <th className='min-w-120px'>Parcelas</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>{formatMask(billingInstallment?.amount)}</b>
                  </td>

                  <td>
                    <b>{billing?.paymentOption?.name}</b>
                  </td>

                  <td>
                    <b>{formatDate(String(billing?.createdAt))}</b>
                  </td>

                  <td>
                    <b>
                      {billingInstallment?.installmentsNumber ?? billingInstallment?.installment} /{' '}
                      {billing?.installments.length}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='container mt-5'>
            <div className='row p-5' style={{backgroundColor: 'rgb(249 234 233)'}}>
              <div className='col-2'>
                <KTIcon iconName='cross' className='fs-1' />
              </div>

              <div className='col-7 fw-bold'>
                {billing?.paymentOption.category.name}
                <div className='col-4 mb-2 mt-2'>
                  {billing?.recurrencePayment.creditCard.holderName}{' '}
                </div>

                <div className='col-4 mb-2'>
                  ****
                  {billing?.recurrencePayment.creditCard.lastNumbers}{' '}
                </div>
                <div className='col-4 mb-2'>
                  {cpfMask(billing?.recurrencePayment.creditCard.holderDocument)}{' '}
                </div>

                <div className='col-4 mb-2'>
                  {billing?.recurrencePayment.creditCard.expirationMonth} /{' '}
                  {billing?.recurrencePayment.creditCard.expirationYear}
                </div>
              </div>

              <div className='col-1'></div>
              <div className='row col'>
                <button className='btn btn-danger' onClick={() => hadlePaymentCart()}>
                  Pagar
                </button>
              </div>
            </div>
          </div>
          <div className='col-12 mt-5'>
            <button onClick={() => closeModal()} className='btn btn-info'>
              Voltar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
