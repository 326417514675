import * as Yup from 'yup'
export const creditCardSchema = (hasAddress: boolean = false) => {
  return Yup.object().shape({
    document: Yup.string()
      .min(11, 'Minimo 11 números')
      .max(11, 'Máximo 11 números')
      .required('Campo obrigatório'),
    hasAddress: Yup.boolean().required(),
    cvv: Yup.string()
      .min(3, 'Minimo 3 números')
      .max(3, 'Máximo 3 números')
      .required('Campo obrigatório'),
    cardNumber: Yup.string()
      .min(16, 'Minimo 16 números')
      .max(16, 'Máximo 16 números')
      .required('Campo obrigatório'),
    holder: Yup.string().required('Campo obrigatório'),
    expiry: Yup.string().min(5, 'Minimo 4 caracteres').required('Campo obrigatório'),
    zipCode: Yup.string()
      .min(8, 'Minimo 8 caracteres')
      .max(8, 'Máximo 8 números')
      .when('hasAddress', {
        is: () => hasAddress,
        then: () => Yup.string().required('Informe um CEP'),
        otherwise: () => Yup.string().optional(),
      }),
    state: Yup.string()
      .required('Campo obrigatório')
      .when('hasAddress', {
        is: () => hasAddress,
        then: () => Yup.string().required('Informe um Estado'),
        otherwise: () => Yup.string().optional(),
      }),
    city: Yup.string()
      .required('Campo obrigatório')
      .when('hasAddress', {
        is: () => hasAddress,
        then: () => Yup.string().required('Informe uma Cidade'),
        otherwise: () => Yup.string().optional(),
      }),
    neighborhood: Yup.string().when('hasAddress', {
      is: () => hasAddress,
      then: () => Yup.string().required('Informe um Bairro'),
      otherwise: () => Yup.string().optional(),
    }),
    street: Yup.string().when('hasAddress', {
      is: () => hasAddress,
      then: () => Yup.string().required('Informe uma Rua'),
      otherwise: () => Yup.string().optional(),
    }),
    number: Yup.string()
      .min(1, 'Minimo 1 caracteres')
      .when('hasAddress', {
        is: () => hasAddress,
        then: () => Yup.string().required('Informe um Número'),
        otherwise: () => Yup.string().optional(),
      }),
  })
}
