import { Outlet, useParams } from "react-router-dom"
import { PageLink, PageTitle } from "../../_metronic/layout/core"
import { useEffect, useState } from "react";
import { TFranchiseRequest, franchiseInitValues as initialValues } from "../../coreGlobal/models/franchise/franchise";
import { useFormik } from "formik";
import { LoadingSpinner } from "../../utils/loading/loadingSpinner";
import { FranchiseProvider, useFormFranchise } from "./core/franchiseProvider";
import { phoneMask } from "../../utils/phoneMask";
import { cepMask } from "../../utils/cepMask";
import Swal from "sweetalert2";
import { updateFranchise } from "./core/_request";
import { PeopleProvider, usePeople } from "../../coreGlobal/providers/PersonProvider";
import { OwnerForm } from "./components/OwnerForm";
import { MachineForm } from "./components/MachineForm";
import { ProductForm } from "./components/ProductForm";
import { buildCreateRequest } from "./core/buildCreateRequest";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";
import FranchiseForm from "./components/FranchiseForm";
import { PersonValidSchema } from "../../coreGlobal/validation/PersonVali";
import { SettingOption } from "./components/SettingOption";
import { PartnershipForm } from "./components/PartnershipForm";

const FranchiseBreadcrumbs: Array<PageLink> = [
  {
    title: 'Franquias',
    path: '/franchise',
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
]

export const Update = () => {
  let { id } = useParams();
  const [loadingPage, setLoadingPage] = useState(false)
  const [loading, setLoading] = useState(false)
  const { persons, setPersons, setFieldPersons } = usePeople()
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || '#franchise');


  const {
    getDataFranchise, franchise,
    getDataMachines,
    getDataProducts
  } = useFormFranchise()

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true);
      await getDataFranchise(String(id))
      await getDataMachines()
      await getDataProducts()
      setLoadingPage(false);
    }
    init()
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: PersonValidSchema(true),
    onSubmit: async (values) => {
      const valuesFilter = await buildCreateRequest(values)
      await submit(valuesFilter)
    }
  })

  useEffect(() => {
    if (franchise) {
      const init = async (franchise) => {
        const addresses = franchise.person?.addresses?.map((value) => ({
          id: value.id,
          isMain: value.isMain,
          zipCode: cepMask(value.zipCode),
          street: value.street,
          number: value.number,
          neighborhood: value.neighborhood,
          city: value.city.id,
          state: value.city.state.id,
          ...(value.complement && { complement: value.complement }),
        }));

        const banksData = franchise.person?.bank?.map((value) => ({
          id: value.id || 0,
          isMain: value.main || true,
          account: value.account || '',
          accountDv: value.accountDv || 0,
          agency: value.agency || '',
          agencyDv: value.agencyDv || 0,
          bank: String(value.bank.id) || '',
          accountType: value.accountType.id || 1,
          pix: value.pixKey || '',
          updatedAt: value.updatedAt || '',
          createdAt: value.createdAt || '',
        }))

        const contactsData = franchise.person?.contacts?.map((value) => ({
          id: value.id,
          type: value.typeId,
          phone: phoneMask(value.phone),
        }))

        const fieldOwners = franchise.owners?.map((value) => {
          setPersons([...persons, value])
          return { name: '', document: '', dropdownOpen: false, isFetching: false, search: [] }
        })
        setFieldPersons(fieldOwners)

        await formik.setValues({
          id: franchise.id,
          personId: franchise.person.id,
          type: 2,
          name: franchise.name,
          startTime: franchise.startTime,
          endTime: franchise.endTime,
          franchiseEmail: franchise.email,
          socialReason: franchise.person.name,
          document: franchise.person.juridicalPerson.cnpj,
          fantasyName: franchise.person.juridicalPerson.fantasyName,
          email: franchise.person.email,
          addresses: addresses,
          contacts: contactsData.length > 0 ? contactsData : [{ type: 0, phone: "" }],
          bankData: banksData,
          machines: franchise.machines,
          products: franchise.products,
          owners: franchise.owners?.map((value) => value.id)
        });
      }
      init(franchise)
    }
  }, [franchise])

  const submit = async (req: TFranchiseRequest) => {
    await Swal.fire({
      title: 'Deseja atualizar a franquia?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true)
        try {
          await updateFranchise(String(id), req)
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Salvo com sucesso',
            showConfirmButton: false,
            timer: 1500
          })

        } catch (error: any) {
          if (error.response.status === 422) {
            let errorText = '';
            for (let key in error.response.data.errors) {
              errorText += '<li>' + error.response.data.errors[key][0] + '</li>';
            }
            await Swal.fire({
              icon: 'error',
              title: 'Ops! Revise seu formulário e tente novamente!',
              html: errorText
            });
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 2000
            })
          }
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const handleClick = (tab) => {
    setActiveTab(tab); // Atualiza a aba ativa
    window.location.hash = tab
    localStorage.setItem('activeTab', tab); // Salva a aba ativa no localStorage para persistir entre as sessões
  };

  useEffect(() => {
    // Verifica se há uma aba ativa no localStorage e se sim, define como ativa
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
      window.location.hash = savedTab;
    }
  }, []);

  return (
    <>
      {loadingPage ? <LoadingSpinner title={"Carregando Dados da Franquia"} /> :
        <>
          <Outlet />
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
                    <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                          {franchise?.person.name}
                        </a>
                        <KTIcon iconName='verify' className='fs-1 text-primary' />
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                          <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                          Ativa
                        </a>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                          <KTIcon iconName='geolocation' className='fs-4 me-1' />
                          {franchise?.name}
                        </a>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                        >
                          <KTIcon iconName='sms' className='fs-4 me-1' />
                          {franchise?.person?.email}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <button
                      className={`nav-link ${activeTab === '#franchise' ? 'active' : ''}`}
                      id='pills-data-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-data-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-data-franchise'
                      aria-selected={activeTab === '#franchise'}
                      onClick={() => handleClick('#franchise')}
                    >
                      Dados da Franquia
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className={`nav-link ${activeTab === '#product' ? 'active' : ''}`}
                      id='pills-product-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-product-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-product-franchise'
                      aria-selected={activeTab === '#product'}
                      onClick={() => handleClick('#product')}
                    >
                      Produtos
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className={`nav-link ${activeTab === '#machine' ? 'active' : ''}`}
                      id='pills-machine-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-machine-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-machine-franchise'
                      aria-selected={activeTab === '#machine'}
                      onClick={() => handleClick('#machine')}
                    >
                      Máquinas
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className={`nav-link ${activeTab === '#owner' ? 'active' : ''}`}
                      id='pills-owner-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-owner-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-owner-franchise'
                      aria-selected={activeTab === '#owner'}
                      onClick={() => handleClick('#owner')}
                    >
                      Sócios
                    </button>
                  </li>
                  {franchise && franchise.config && (
                    <li className='nav-item'>
                      <button
                        className={`nav-link ${activeTab === '#settings' ? 'active' : ''}`}
                        id='pills-settings-franchise-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-settings-franchise'
                        type='button'
                        role='tab'
                        aria-controls='pills-settings-franchise'
                        aria-selected={activeTab === '#settings'}
                        onClick={() => handleClick('#settings')}
                      >
                        Configurações
                      </button>
                    </li>
                  )}
                  <li className='nav-item'>
                    <button
                      className={`nav-link ${activeTab === '#partnership' ? 'active' : ''}`}
                      id='pills-partnership-franchise-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-partnership-franchise'
                      type='button'
                      role='tab'
                      aria-controls='pills-partnership-franchise'
                      aria-selected={activeTab === '#partnership'}
                      onClick={() => handleClick('#partnership')}
                    >
                      Parcerias
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <PageTitle breadcrumbs={FranchiseBreadcrumbs}>Editar Franquia</PageTitle>

          <div className='tab-content'>
            <div className={`tab-pane fade ${activeTab === '#franchise' ? 'show active' : ''} p-4 pt-0 pt-md-4`} id='pills-data-franchise' role='tabpanel' aria-labelledby='pills-checklist-tab'>
              <FranchiseForm formik={formik} loading={loading} mode="UPDATE" />
            </div>
            <div className={`tab-pane fade ${activeTab === '#product' ? 'show active' : ''} p-4 pt-0 pt-md-4`} id='pills-product-franchise' role='tabpanel' aria-labelledby='pills-checklist-tab'>
              <ProductForm formik={formik} />
            </div>
            <div className={`tab-pane fade ${activeTab === '#machine' ? 'show active' : ''} p-4 pt-0 pt-md-4`} id='pills-machine-franchise' role='tabpanel' aria-labelledby='pills-checklist-tab'>
              <MachineForm formik={formik} />
            </div>
            <div className={`tab-pane fade ${activeTab === '#owner' ? 'show active' : ''} p-4 pt-0 pt-md-4`} id='pills-owner-franchise' role='tabpanel' aria-labelledby='pills-checklist-tab'>
              <OwnerForm formik={formik} />
            </div>
            {franchise && franchise.config &&
              <div className={`tab-pane fade ${activeTab === '#settings' ? 'show active' : ''} p-4 pt-0 pt-md-4`} id='pills-settings-franchise' role='tabpanel' aria-labelledby='pills-checklist-tab'>
                <SettingOption franchiseId={franchise.id} config={franchise.config} />
              </div>
            }
            <div className={`tab-pane fade ${activeTab === '#partnership' ? 'show active' : ''} p-4 pt-0 pt-md-4`} id='pills-partnership-franchise' role='tabpanel' aria-labelledby='pills-checklist-tab'>
              <PartnershipForm formik={formik} />
            </div>


          </div>
        </>
      }
    </>
  )
}

export const FranchiseEditPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={FranchiseBreadcrumbs}>Atualizar Franquia</PageTitle>
      <FranchiseProvider>
        <PeopleProvider>
          <Update />
        </PeopleProvider>
      </FranchiseProvider>
    </>
  )
}