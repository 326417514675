import {useEffect, useState} from 'react'
import Cards from 'react-credit-cards-2'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import '../styles/indicator.scss'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import {TCreditCardForm} from '../core/creditCardPayment'
import {creditCardSchema} from '../core/Validation/CreditCardVali'
import {buildCreditCardRequest} from '../core/Validation/biuldRequestCart'
import {changeCartReq} from '../core/_request'
import {cpfMask, formatDateCrediCard} from '../../../payment/utils/mask'

const defaultCreditCardData = {
  cardNumber: '',
  expiry: '',
  cvv: '',
  holder: '',
  document: '',
}

const ChangeCreditCardForm = ({token}) => {
  const [formData, setFormData] = useState<TCreditCardForm>(defaultCreditCardData)
  const formik = useFormik({
    initialValues: defaultCreditCardData,
    validationSchema: creditCardSchema,
    isInitialValid: true,
    onSubmit: async () => {
      try {
        const requestData = buildCreditCardRequest(formData)

        await changeCartReq(requestData, String(token))
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Mundança de cartão realizada com sucesso!!',
          timer: 3000,
        })
        setTimeout(function () {
          window.location.reload()
        }, 2000)
      } catch (error: any) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: error.response.data.message ?? 'Erro ao gerar pagamento.',
          timer: 3000,
        })
      }
    },
  })

  function handleSubmit(event) {
    event.preventDefault()
    formik.handleSubmit()
  }

  const handleInputCardChange = (evt) => {
    const {name, value} = evt.target

    if (name == 'cardNumber') {
      const valCardFormat = value.replace(/\D/g, '')
      setFormData({...formData, [name]: valCardFormat.slice(0, 16)})
      formik.setFieldValue(name, valCardFormat)
      return
    }

    if (name == 'document') {
      const valCardFormat = value.replace(/\D/g, '')
      setFormData({...formData, [name]: cpfMask(valCardFormat.slice(0, 11))})
      formik.setFieldValue(name, valCardFormat)
      return
    }

    setFormData({...formData, [name]: value})
    formik.setFieldValue(name, value)
  }

  const handlePaste = (evt) => {
    evt.preventDefault()
    const {name} = evt.target

    const pastedText = evt.clipboardData.getData('Text')

    const formatedValue = pastedText.replace(/\D/g, '')

    setFormData({...formData, [name]: formatedValue.slice(0, 16)})
    formik.setFieldValue(name, formatedValue.slice(0, 16))
  }

  return (
    <>
      <div className='container pt-10'>
        <div className='col-12'>
          <div className='col text-start'>
            <h3 style={{color: '#D31145'}} className='mb-4 text-start'>
              Dados do cartão
            </h3>
            <Cards
              number={formData.cardNumber}
              expiry={formData.expiry}
              cvc={formData.cvv}
              name={formData.holder}
            />
            <form
              id='data-kt-users-modal-action'
              className='form'
              onSubmit={handleSubmit}
              noValidate
            >
              <div className='position-relative mb-5'>
                <label className='d-flex align-items-center fs-6 mt-4 fw-bold form-label mb-2'>
                  <span className='required'>Número do Cartão</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Número do cartão'
                  ></i>
                </label>
                <input
                  type='text'
                  name='cardNumber'
                  maxLength={16}
                  value={formData.cardNumber}
                  className='form-control form-control-solid'
                  onChange={handleInputCardChange}
                  onPaste={handlePaste}
                />
                {<small className='text-danger'>{formik.errors.cardNumber}</small>}
              </div>
              <div className='d-flex flex-column mb-7 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span className='required'>Nome impresso no cartão</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Nome impresso no cartão'
                  ></i>
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='holder'
                  placeholder='Nome impresso no cartão'
                  value={formData.holder}
                  onChange={handleInputCardChange}
                />
                {<small className='text-danger'>{formik.errors.holder}</small>}
              </div>
              <div className='mb-4'>
                <div className='row'>
                  <div className='col-lg-8'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>Válido até</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Validade'
                      ></i>
                    </label>
                    <input
                      type='text'
                      maxLength={5}
                      name='expiry'
                      className='form-control form-control-solid'
                      placeholder='Val'
                      value={formatDateCrediCard(formData.expiry)}
                      onChange={handleInputCardChange}
                    />
                    {<small className='text-danger'>{formik.errors.expiry}</small>}
                  </div>
                  <div className='col-lg-4'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className='required'>CVV</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Valor de Verificação do Cartão'
                      ></i>
                    </label>
                    <input
                      type='number'
                      name='cvv'
                      placeholder='***'
                      className='form-control form-control-solid'
                      onChange={handleInputCardChange}
                    />
                    {<small className='text-danger'>{formik.errors.cvv}</small>}
                  </div>
                </div>
              </div>
              <div className='mb-4 text-start'>
                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                  <span>Documento</span>
                </label>
                <input
                  type='text'
                  maxLength={14}
                  className='form-control form-control-solid'
                  name='document'
                  value={formData.document}
                  onChange={handleInputCardChange}
                />
              </div>
              <div className='mb-4 text-start'>
                <button
                  type='submit'
                  style={{borderRadius: 36}}
                  className='btn btn-success col-lg-12'
                  onClick={handleSubmit}
                  disabled={formik.isSubmitting}
                >
                  <span className='indicator-label'>Confirmar Aleteração</span>
                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      Aguarde...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export {ChangeCreditCardForm}
