import axios, { AxiosResponse } from 'axios'
import { TCreditCard } from './creditCardPayment'
import { SimpleResponse } from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const CHANGE_CART_URL = `${API_URL}/recurrence-payments`

export const changeCartReq = (payment, id: string): Promise<TCreditCard | undefined> => {
  return axios
    .put(`${CHANGE_CART_URL}/${id}/change-credit-card`, payment)
    .then((response: AxiosResponse<SimpleResponse<TCreditCard>>) => response.data)
    .then((response: SimpleResponse<TCreditCard>) => response.data)
}
