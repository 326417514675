import {SplitInfoCartao} from '../../../../../components/SalePaymentConfig/components/SplitInfoCartao'
import {SplitInfoCartaoRecorrente} from '../../../../../components/SalePaymentConfig/components/SplitInfoCartaoRecorrente'
import {SplitInfoPix} from '../../../../../components/SalePaymentConfig/components/SplitInfoPix'
import {methodsEnableENUM} from '../../../create/core/models/methods'
import {IPayment} from '../../../create/core/models/payment'

interface ISplit {
  payment: IPayment[]
}

const SwitchMethod = ({pay}: {pay: IPayment}) => {
  if (pay.value === 0) return <></>

  switch (Number(pay.method)) {
    case 1:
      return <SplitInfoPix value={pay.value} date={new Date()} />
    case 2:
      return (
        <SplitInfoCartao
          value={pay.value}
          date={new Date()}
          installments={pay.numberOfInstallments}
        />
      )
    case 3:
      return (
        <SplitInfoCartaoRecorrente
          value={pay.value}
          date={new Date()}
          installments={pay.numberOfInstallments}
        />
      )

    default:
      return <></>
  }
}

export const SplitRecurrentPayment = ({payment}: ISplit) => {
  return (
    <div className='col-12'>
      {payment?.map((pay: IPayment, index: number) => {
        return <SwitchMethod pay={pay} />
      })}
    </div>
  )
}
