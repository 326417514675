import {TStateResponse} from '../models/Address/address'
import {TViaCepResponse} from '../models/Address/viaCep'
import {WithChildren} from '../../_metronic/helpers'
import {FC, createContext, useContext, useState} from 'react'
import {getCepByViaCep, getStatesAndCities} from '../_resquest'
import Swal from 'sweetalert2'

interface IAdressContext {
  dataCep: TViaCepResponse | undefined
  setDataCep: React.Dispatch<React.SetStateAction<TViaCepResponse | undefined>>
  getDataCep: (cep: string) => void

  dataStates: TStateResponse[] | undefined
  setDataStates: React.Dispatch<React.SetStateAction<TStateResponse[] | undefined>>

  loadingPage: boolean
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>
  getDataStates: () => void
}

const AdressContext = createContext<IAdressContext>({} as IAdressContext)

const AdressProvider: FC<WithChildren> = ({children}) => {
  const [dataStates, setDataStates] = useState<TStateResponse[] | undefined>()
  const [dataCep, setDataCep] = useState<TViaCepResponse>()
  const [loadingPage, setLoadingPage] = useState(false)

  const getDataCep = async (cep: string) => {
    try {
      const data = await getCepByViaCep(cep)
      setDataCep(data)
    } catch (error: any) {
      await Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error.response?.data?.message || 'Erro ao buscar CEP',
      })
      setLoadingPage(false)
    }
  }

  const getDataStates = async () => {
    try {
      const data = await getStatesAndCities()
      setDataStates(data)
    } catch (error: any) {
      await Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error.response.data.message,
      })
      setLoadingPage(false)
    }
  }

  return (
    <AdressContext.Provider
      value={{
        dataCep,
        setDataCep,
        getDataCep,
        dataStates,
        setDataStates,
        getDataStates,
        loadingPage,
        setLoadingPage,
      }}
    >
      {children}
    </AdressContext.Provider>
  )
}

const useAdress = () => useContext(AdressContext)
export {AdressProvider, useAdress}
