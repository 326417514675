import { TCreditCardForm } from "../creditCardPayment"

export const buildCreditCardRequest = (data: TCreditCardForm) => {
    return {
        document: data.document,
        number: data.cardNumber,
        holder: data.holder,
        month: data.expiry.slice(0,2),
        year: '20' + data.expiry.slice(3,5),
        cvv: data.cvv
    }
}