import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {formatDate, formatDateTime} from '../../utils/dayJs/format'
import {KTIcon, toAbsoluteUrl} from '../../_metronic/helpers'
import React, {useEffect, useState} from 'react'
import {getPersonById} from '../../coreGlobal/_resquest'
import {IPersonResponse} from '../../coreGlobal/models/person'
import {LoadingSpinner} from '../../utils/loading/loadingSpinner'
import {phoneMask} from '../../utils/phoneMask'
import {cepMask} from '../../utils/cepMask'
import {SalesForm} from './components/SalesForm'
import {PeopleProvider, usePeople} from '../../coreGlobal/providers/PersonProvider'
import {ContractProvider} from '../sales/core/ContractProvider'
import {BillingProvider} from '../sales/core/billingProvider'
import {ListEvaluation} from './components/ListEvaluation'
import {ListBudget} from './components/ListBudget'
import {ListMedicalRecords} from './components/ListMedicalRecords'
import {ListSalesHistorySchedules} from '../sales/components/ListSalesHistorySchedules'
import {getsaleMedicalRecordsByClientId} from '../sales/core/_request'
import {StatusModal} from './components/StatusModal'
import {ListStatusHistory} from './components/ListStatusHistory'
import {ListMedicalCondition} from './components/ListMedicalCondition'
import {PersonEditModal} from '../../components/Person/PersonEditModal'
import {useClientCart} from '../sales/create/core/clientProvider'
import {ListObservationsHistory} from './components/ListObservationsHistory'
import useAuthorization from '../../hooks/useAuthorization'
import Swal from 'sweetalert2'

const peopleBreadcrumbs: Array<PageLink> = [
  {
    title: 'Pessoa',
    path: '/people',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function getAvatarImg(person) {
  if (person?.physicalPerson?.hideAvatar) {
    return 'avatar-neutro.svg'
  }
  switch (person?.physicalPerson?.gender) {
    case 'F':
      return 'avatar-feminino.svg'
    case 'M':
      return 'avatar-masculino.svg'
    default:
      return 'avatar-neutro.svg'
  }
}

const ListSalesHistoryMedicalRecord = ({clientID}) => {
  const [dataSale, setDataSale] = useState()
  useEffect(() => {
    const init = async () => {
      const salesHistorySchedules = await getsaleMedicalRecordsByClientId(Number(clientID))
      setDataSale(salesHistorySchedules)
    }
    init()
  }, [])

  return <ListSalesHistorySchedules salesHistorySchedules={dataSale} />
}

const Show = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const [person, setPerson] = useState<IPersonResponse>()
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false)
  const {setShowEditPersonModal, showEditPersonModal} = usePeople()

  useEffect(() => {
    const init = async () => {
      setLoadingPage(true)
      try {
        const data = await getPersonById(String(id))
        setPerson(data)
        setLoadingPage(false)
      } catch (error: any) {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message,
          showConfirmButton: false,
          allowOutsideClick: false,
        })
      }
    }
    init()
  }, [])

  const get_badge_class = () => {
    let badgeClass = ''
    switch (person?.lastClientHistory?.status.name) {
      case 'Ativo':
        badgeClass = 'success'
        break
      case 'Restrito':
        badgeClass = 'warning'
        break
      default:
        badgeClass = 'danger'
        break
    }
    return badgeClass
  }

  const get_address = () => {
    const mainStateAndCity = person?.addresses?.[0]?.city
    const mainNeighborhood = person?.addresses?.[0]?.neighborhood

    return (
      <>
        {mainStateAndCity?.state.uf} - {mainNeighborhood}
      </>
    )
  }

  const isAuthorizedToUpdate = useAuthorization('persons.update')

  return (
    <>
      {loadingPage ? (
        <LoadingSpinner title={'Carregando Dados Pessoais'} />
      ) : (
        <>
          <div className='card-body'></div>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img
                      src={toAbsoluteUrl(`/media/avatars/${getAvatarImg(person)}`)}
                      alt='Metronic'
                    />
                    <div
                      className={`position-absolute translate-middle bottom-0 start-100 mb-6 bg-${get_badge_class()} rounded-circle border border-4 border-white h-20px w-20px`}
                    ></div>
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      {person?.physicalPerson?.preferredName ? (
                        <>
                          <div className='d-flex align-items-center mb-1'>
                            <a
                              href='#'
                              className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                            >
                              {person?.physicalPerson?.preferredName}
                            </a>
                            <a href='#'>
                              <KTIcon iconName='verify' className='fs-1 text-primary' />
                            </a>
                          </div>
                          <div className='d-flex align-items-center mb-2'>
                            <span className='fs-8 me-1'>{person?.name}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='d-flex align-items-center mb-1'>
                            <a
                              href='#'
                              className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                            >
                              {person?.name}
                            </a>
                            <a href='#'>
                              <KTIcon iconName='verify' className='fs-1 text-primary' />
                            </a>
                          </div>
                        </>
                      )}
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                          <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                          {person?.lastClientHistory && (
                            <button
                              style={{
                                margin: 0,
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              onClick={() => setShowStatusModal(true)}
                            >
                              <span className={`badge badge-${get_badge_class()}`}>
                                {person?.lastClientHistory?.status?.name}
                              </span>
                            </button>
                          )}
                        </a>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                          <KTIcon iconName='geolocation' className='fs-4 me-1' />
                          {get_address()}
                        </a>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                        >
                          <KTIcon iconName='sms' className='fs-4 me-1' />
                          {person?.email}
                        </a>
                      </div>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'></div>
                    </div>
                    <div className='d-flex my-7'>
                      {isAuthorizedToUpdate && (
                        <button
                          className='btn btn-sm hover-scale me-3'
                          style={{
                            backgroundColor: '#f1416c',
                            color: 'white',
                          }}
                          onClick={() => setShowEditPersonModal(true)}
                        >
                          Editar Cliente
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <button
                      className='nav-link active'
                      id='pills-items-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-items'
                      type='button'
                      role='tab'
                      aria-controls='pills-items'
                      aria-selected='true'
                    >
                      Dados pessoais
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-cobranca-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-cobranca'
                      type='button'
                      role='tab'
                      aria-controls='pills-cobranca'
                      aria-selected='true'
                    >
                      Dados bancários
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-sales-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-sales'
                      type='button'
                      role='tab'
                      aria-controls='pills-sales'
                      aria-selected='true'
                    >
                      Contratos
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-budgets-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-budgets'
                      type='button'
                      role='tab'
                      aria-controls='pills-budgets'
                      aria-selected='true'
                    >
                      Orçamentos
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-medical-records-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-medical-records'
                      type='button'
                      role='tab'
                      aria-controls='pills-medical-records'
                      aria-selected='true'
                    >
                      Prontuário
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-evaluation-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-evaluation'
                      type='button'
                      role='tab'
                      aria-controls='pills-evaluation'
                      aria-selected='true'
                    >
                      Avaliação
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-scheduleHistory-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-scheduleHistory'
                      type='button'
                      role='tab'
                      aria-controls='pills-scheduleHistory'
                      aria-selected='true'
                    >
                      Agendamentos
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-medicalCondition-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-medicalCondition'
                      type='button'
                      role='tab'
                      aria-controls='pills-medicalCondition'
                      aria-selected='true'
                    >
                      Condição médica
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-statusHistory-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-statusHistory'
                      type='button'
                      role='tab'
                      aria-controls='pills-statusHistory'
                      aria-selected='true'
                    >
                      Histórico de status
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='nav-link'
                      id='pills-observationsHistory-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-observationsHistory'
                      type='button'
                      role='tab'
                      aria-controls='pills-observationsHistory'
                      aria-selected='true'
                    >
                      Anotação Interna
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='tab-content' id='pills-tabContent'>
            <div
              className='tab-pane fade show active p-4 pt-0 pt-md-4'
              id='pills-items'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações Pessoa Fisica</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <tbody>
                        <tr>
                          <td>
                            <b>RG:</b> {person?.physicalPerson?.rg}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>CPF:</b> {person?.physicalPerson?.document}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Idade:</b> {person?.physicalPerson?.age}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Data de Nascimento:</b>{' '}
                            {person?.physicalPerson?.birthDate
                              ? formatDate(person.physicalPerson.birthDate)
                              : ''}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Gênero:</b>{' '}
                            {person?.physicalPerson?.gender == 'F' ? 'Feminino' : 'Masculino'}{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Profissão:</b> {person?.physicalPerson?.job}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Nacionalidade:</b> {person?.physicalPerson?.nationality?.name}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Tempo de agendamento reduzido?:</b>{' '}
                            {person?.reduceTime ? 'Sim' : 'Não'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Avatar neutro?:</b>{' '}
                            {person?.physicalPerson?.hideAvatar ? 'Sim' : 'Não'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações de Contato</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table
                      id='teste'
                      className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
                    >
                      <tbody>
                        <tr>
                          <td>
                            <b>E-mail:</b> {person?.email}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className='table table-row-dashed table-row-gray-300'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <b>Tipo</b>
                          </th>
                          <th>
                            <b>Número</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {person?.contacts.map((contact, index) => (
                          <tr key={index}>
                            <td>
                              <b>Telefone {index + 1} : </b>
                            </td>
                            <td>{contact?.typeName}</td>
                            <td>{phoneMask(contact?.phone)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações de Endereço</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <tbody>
                        {person?.addresses?.map((address, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <b>CEP:</b> {cepMask(address?.zipCode)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Rua:</b> {address?.street}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Número:</b> {address?.number}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Complemento:</b> {address?.complement}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Bairro: </b>
                                {address?.neighborhood}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Cidade:</b> {address?.city?.name} - {address?.city?.state?.uf}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Estado:</b> {address?.city?.state?.name}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-cobranca'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações da Cobrança</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <tbody>
                        {person?.bank?.map((bank, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                Tipo: <b>{bank?.main ? 'Principal' : 'Secundário'}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Banco: <b>{bank?.bank?.code + ' - ' + bank?.bank?.name || ' '}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Agência:{' '}
                                <b>
                                  {bank?.agency + (bank?.agencyDv ? ` - ${bank?.agencyDv}` : '')}
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Conta: <b>{bank?.account + ' - ' + bank?.accountDv || ' '}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Chave PIX: <b>{bank?.pixKey || ' '}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Criado em: <b>{formatDateTime(bank?.createdAt)}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Atualizado em: <b>{formatDateTime(bank?.updatedAt)}</b>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-sales'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações de Contrato</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <SalesForm people={person} />
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-budgets'
              role='tabpanel'
              aria-labelledby='pills-budgets-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações de orçamentos</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <>
                    <ListBudget budgets={person?.budgets} />
                  </>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-medical-records'
              role='tabpanel'
              aria-labelledby='pills-medical-records-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ListMedicalRecords peopleId={Number(id)} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-evaluation'
              role='tabpanel'
              aria-labelledby='pills-checklist-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações de Avaliações</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <>
                    <ListEvaluation clientId={Number(id)} />
                  </>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-scheduleHistory'
              role='tabpanel'
              aria-labelledby='pills-scheduleHistory-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <ListSalesHistoryMedicalRecord clientID={Number(id)} />
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-medicalCondition'
              role='tabpanel'
              aria-labelledby='pills-medicalCondition-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações da Condição Médica</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <>
                    <ListMedicalCondition clientMedicalRestriction={person?.medicalConditions} />
                  </>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-statusHistory'
              role='tabpanel'
              aria-labelledby='pills-statusHistory-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações do Histórico de Status</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <>
                    <ListStatusHistory clientHistory={person?.clientHistory} />
                  </>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade p-4 pt-0 pt-md-4'
              id='pills-observationsHistory'
              role='tabpanel'
              aria-labelledby='pills-observationsHistory-tab'
            >
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Informações de Anotações Internas</h3>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <>
                    <ListObservationsHistory observations={person?.observations} />
                  </>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showStatusModal && <StatusModal data={person} close={() => setShowStatusModal(false)} />}

      {showEditPersonModal && person && (
        <PersonEditModal
          person={person}
          provider={useClientCart}
          index={0}
          close={() => setShowEditPersonModal(false)}
        />
      )}
    </>
  )
}

export const PeopleShowPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={peopleBreadcrumbs}>Dados Pessoais</PageTitle>
      <PeopleProvider>
        <ContractProvider>
          <BillingProvider>
            <Show />
          </BillingProvider>
        </ContractProvider>
      </PeopleProvider>
    </>
  )
}
