import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const CHANGE_CART_URL = `${API_URL}/recurrence-payments`

export const getInfoRecurrenceById = (id: string) => {
  return axios
    .get(`${CHANGE_CART_URL}/${id}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const handlePaymentManualReq = (id: number | string, installment: number, data: any) => {
  return axios
    .put(`${CHANGE_CART_URL}/${id}/manual-payment/${installment}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const handlePayment = (id: number | string, installment: number) => {
  return axios
    .post(`${CHANGE_CART_URL}/${id}/charge-installment/${installment}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}
export const sendLinkChangeCartReq = (id: number) => {
  return axios
    .post(`${CHANGE_CART_URL}/${id}/send-change-credit-card-link`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}
