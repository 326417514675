import {ID} from '../../../_metronic/helpers'
import {People} from '../../clients/list/core/_models'

export type Billing = {
  id: ID
  amount: number
  status: string
  createdAt: string
  updatedAt: string
  paymentOption: PaymentOption
  numberOfInstallments: number
  installments: Array<Installments>
  recurrencePayment: RecurrencePayment
}

export type RecurrencePayment = {
  amount: string
  attemptsFailed: number
  attemptsSuccessful: number
  createdAt: string
  creditCard: CreditCard
  currency: string
  endDate: string
  id: number
  installments: Array<InstallmentsRecurrence>
  nextPaymentDate: string
  startDate: string
  status: string
  updatedAt: string
}

interface CreditCard {
  id: number
  holderName: string
  holderDocument: string
  firstNumbers: string
  lastNumbers: string
  expirationMonth: string
  expirationYear: string
  token: string
  createdAt: string
  updatedAt: string
}

export type TBillingInstallment = {
  id: number
  amount: string
  dueDate: string
  installment: number
  installmentsNumber: number
  status: string
  updatedAt: string
  createdAt: string
}

export type Address = {
  id: ID
  city: {
    id: ID
    name: string
  }
  complement: string
  main: boolean
  neighborhood: string
  number: string
  street: string
  zipCode: string
}

export type PaymentOption = {
  id: ID
  name: string
  active: boolean
  franchise: Franchise
  category: CategoryRecurence
  createdAt: Date
  updatedAt: Date
}

type CategoryRecurence = {
  id: number
  name: string
}

type Franchise = {
  id?: ID
  name?: string
  type?: string
  createdAt?: Date
  updatedAt?: Date
  person?: {
    juridicalPerson?: {
      cnpj?: string
      cnpjWithoutMask?: string
    }
  }
}
export type InstallmentsRecurrence = {
  id: ID
  installmentsNumber: number
  amount: number
  dueDate: string
  i18nStatus: string
  status: string
  createdAt: Date
  updatedAt: Date
}

export type Installments = {
  id: ID
  installment: number
  amount: number
  dueDate: string
  status: string
  i18nStatus: string
  createdAt: Date
  updatedAt: Date
}
export const IContract: FileRequest = {
  file: ``,
  directory: ``,
}
export type FileRequest = {
  file: any
  directory: string
}
