import {useParams} from 'react-router-dom'
import './styles/indicator.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ChangeCreditCardForm} from './components/cardForm'
import {getInfoRecurrenceById} from '../modalRecurrentPayment/core/_request'
import React, {useEffect, useState} from 'react'
import {HasErrorInShowPage} from '../../../../components/HasErrorInShowPage'
import Swal from 'sweetalert2'
import {LoadingSpinner} from '../../../../utils/loading/loadingSpinner'

export const ChangeCartPage = () => {
  let {id} = useParams()
  const [loadingPage, setLoadingPage] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [recurrenceData, setRecurrenceData] = useState<any>()

  useEffect(() => {
    try {
      const init = async () => {
        try {
          setLoadingPage(true)
          const data = await getInfoRecurrenceById(String(id))
          setRecurrenceData(data)
          setLoadingPage(false)
        } catch (e: any) {
          setLoadingPage(false)
          setHasError(true)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: e.response.data.message,
          })
        }
      }

      init()
    } catch (error: any) {
      setLoadingPage(false)
      setHasError(true)
    }
  }, [])

  if (hasError) {
    return <HasErrorInShowPage />
  }

  return (
    <>
      {loadingPage ? <LoadingSpinner title={"Carregando recorrência"} />
        : <>
          <div className="conteiner" style={{backgroundColor: '#D31145', height: '60px'}}>
            <div className="text-center m-4">
              <img src={toAbsoluteUrl('/media/misc/marcaDrlaserBranca.png')} className="h-30px" />
            </div>
          </div>
          <div className="d-flex flex-column flex-center p-0 p-md-10">
            <div
              className="card w-lg-1000px pt-2 pb-5 border border-white"
              style={{borderWidth: '50px'}}
            >
              <div className="card-body p-1 p-md-10">
                <div className="p-2 ps-8 bg-redlaser welcome-payment">
                  <div className="col-12">
                <span className="fs-2 fw-normal text-white">
                  Olá, <span className="fs-2 fw-bold text-white">{recurrenceData?.client?.name}</span>
                </span>
                  </div>
                </div>
              </div>

              <div className="mt-10 px-6 py-4 pb-8" style={{backgroundColor: '#F8EDF1'}}>
                <div className="mb-4">
                  <span className="fs-1 fw-bold text-redlaser mb-4">Serviço</span>
                </div>
                <div>
              <span className="fw-bold">
                Digite os dados do cartão desejado para realizar o pagamento da parcela em aberto.
              </span>
                </div>
              </div>

              <div className="p-2">
                <ChangeCreditCardForm token={id} />
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}
