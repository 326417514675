import { KTIcon } from "../../../../_metronic/helpers"
import { Address, Billing } from "../../core/_models"
import { CreditCardAccordion } from "./CreditCardAccordion"
import { PixPaymentAccordion } from "./PixPaymentAccordion"

const conditionalPaymentAccordionRender = (billing: Billing, saleId: string, adress:Address) => {
    if (billing.paymentOption.id === 1) {
        return <PixPaymentAccordion billing={billing} />
    }

    if (billing.paymentOption.id === 2 || billing.paymentOption.id === 3) {
        return <CreditCardAccordion billing={billing} saleId={saleId} adress={adress} />
    }
}

interface IPaymentAccordion {
    billing: Billing,
    saleId: string,
    adress: Address
}

const PaymentAccordion = ({ billing, saleId, adress }: IPaymentAccordion) => {
    return (
        <>
            {
                billing.status === 'Pendente' ?
                    conditionalPaymentAccordionRender(billing, saleId, adress)
                    :
                    <div className='accordion-item m-7'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed'
                                style={{ backgroundColor: '#D31145', color: 'white', borderRadius: '15px' }}
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_3'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_3'
                            >
                                Cobrança paga
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_3'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_3'
                            data-bs-parent='#kt_accordion_1'
                        >
                            <div className='accordion-body'>
                                <div className='container p-5'>
                                    <div className="col-12 justify-content-center d-block badge badge-success p-5">
                                        <h2 className="text-light p-5 fs-1">Pagamento confirmado</h2>
                                        <i className="bi bi-check-circle p-5 fs-1 text-light"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export { PaymentAccordion }
