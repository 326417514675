import React, {FC, useState, createContext, useContext, useEffect} from 'react'

import {v4 as uuidv4} from 'uuid'
import {IPayment} from '../../../create/core/models/payment'
import {WithChildren} from '../../../../../_metronic/helpers'

interface IRecurrentPaymentContext {
  payment: IPayment[]
  addMethodPayment: () => void
  removeMethodPayment: (uuidV4: string) => void
  checkPaymentMethod: () => void
  handleChangeTypeMethodPayment: (index: any, e: any) => void
  handleChangeInstallments: (index: any, e: any) => void
  handleChangeValueMethodPayment: (index: any, e: any) => void
}

const DEFAULT_PAYMENT: IPayment = {
  paymentRef: String(uuidv4()),
  method: 1,
  value: 0,
  numberOfInstallments: -1,
  recurrency: false,
}

const manualPayment = [5, 11, 6]

const RecurrentPaymentContext = createContext<IRecurrentPaymentContext>(
  {} as IRecurrentPaymentContext
)

const RecurrentPaymentProvider: FC<WithChildren> = ({children}) => {
  const [payment, setPayment] = useState<IPayment[]>([DEFAULT_PAYMENT])

  const addMethodPayment = () => {
    setPayment([
      ...payment,
      {
        ...DEFAULT_PAYMENT,
        paymentRef: String(uuidv4()),
      },
    ])
  }

  const removeMethodPayment = (uuidV4: string) => {
    setPayment(payment.filter((pay) => pay.paymentRef !== uuidV4))
  }

  const checkPaymentMethod = () => {
    const hasManualPayment = manualPayment.some((py) => {
      return payment.some((mtod) => {
        return py == mtod.method
      })
    })
    return hasManualPayment
  }

  const handleChangeTypeMethodPayment = (index, e) => {
    let newData = [...payment]
    let method = 1
    let recurrency = false

    switch (Number(e.target.value)) {
      case 1: //PIX
        method = 1
        break
      case 2: // CARTAO
        method = 2
        break
      case 3: // CARTAO COM RECORRENCIA
        method = 3
        recurrency = true
        break
      default:
        method = Number(e.target.value)
        break
    }

    newData[index] = {
      ...payment[index],
      method: method,
      value: 0,
      numberOfInstallments: recurrency ? 2 : 1,
      recurrency: recurrency,
    }
    setPayment(newData)
  }

  const handleChangeValueMethodPayment = (index, value) => {
    let newData = [...payment]

    newData[index] = {
      ...payment[index],
      value: value,
    }
    setPayment(newData)
  }

  const handleChangeInstallments = (index, installments) => {
    let newData = [...payment]
    newData[index] = {
      ...payment[index],
      numberOfInstallments: Number(installments),
    }
    setPayment(newData)
  }

  return (
    <RecurrentPaymentContext.Provider
      value={{
        payment,
        addMethodPayment,
        removeMethodPayment,
        handleChangeInstallments,
        handleChangeTypeMethodPayment,
        handleChangeValueMethodPayment,
        checkPaymentMethod,
      }}
    >
      {children}
    </RecurrentPaymentContext.Provider>
  )
}

const useRecurrentPayment = () => useContext(RecurrentPaymentContext)

export {RecurrentPaymentProvider, useRecurrentPayment}
