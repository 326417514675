import {useEffect, useState} from 'react'
import {BillingDetailModalHeader} from './billing-detail-modal/BillingDetailModalHeader'
import {Billing, Installments, InstallmentsRecurrence, TBillingInstallment} from '../core/_models'
import {formatMask} from '../../../utils/formatMonetaryToBr'
import {formatDate} from '../../../utils/dayJs/format'
import {useBillingContext} from '../core/billingProvider'
import {PaymentRecurrentModal} from './modalRecurrentPayment/paymentModal'
import {ChangeRecurrentModal} from './modalRecurrentPayment/changeModal'
import {KTIcon} from '../../../_metronic/helpers'
import {cpfMask} from '../payment/utils/mask'
import {sendLinkChangeCartReq} from './modalRecurrentPayment/core/_request'
import {LoadingSpinner} from '../../../utils/loading/loadingSpinner'
import Swal from 'sweetalert2'
import {MenuComponent} from '../../../_metronic/assets/ts/components/MenuComponent'

interface IBilling {
  billing: Billing | null
}

interface ITbodyProps {
  billing: Billing | null
  verifyAction: (action: string, billing: Installments | InstallmentsRecurrence) => void
  options: {value: string; label: string}[]
}

const BillingDetailModal = ({billing}: IBilling) => {
  const optionInitValue = [
    {
      value: 'change',
      label: 'Alterar forma de pagamento',
    },
    {
      value: 'payment',
      label: 'Cobrar parcela',
    },
  ]

  const [options, setOptions] = useState(optionInitValue)
  const [dataPaymentRecurrent, setDataPaymentRecurrent] = useState<TBillingInstallment>()
  const [dataChangeRecurrent, setDataChangeRecurrent] = useState()
  const [openPaymentRecurrentModal, setOpenPaymentRecurrentModal] = useState(false)
  const [openChangeRecurrentModal, setOpenChangeRecurrentModal] = useState(false)
  const [openDetailModal, setOpenDetailModal] = useState(true)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const veriifyAction = (action, billing) => {
    if (action === 'payment') {
      setDataPaymentRecurrent(billing)
      setOpenDetailModal(false)
      setOpenPaymentRecurrentModal(true)
      setOpenChangeRecurrentModal(false)
      return
    }

    setDataChangeRecurrent(billing)
    setOpenDetailModal(false)
    setOpenPaymentRecurrentModal(false)
    setOpenChangeRecurrentModal(true)
  }

  const closeModals = () => {
    setOpenPaymentRecurrentModal(false)
    setOpenChangeRecurrentModal(false)
    setOpenDetailModal(true)
  }

  const sendLinkChangeCart = async () => {
    Swal.fire({
      title: 'Deseja enviar um link para realizar a troca de cartão ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true)
          await sendLinkChangeCartReq(Number(billing?.recurrencePayment?.id))
          setLoading(false)

          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Link enviado com sucesso!!',
            timer: 3000,
          })
        } catch (error: any) {
          setLoading(false)
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: error.response.data.message,
            timer: 3000,
          })
        }
      }
    })
  }

  return (
    <>
      {loading && <LoadingSpinner />}

      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <BillingDetailModalHeader />
            {openDetailModal && !openChangeRecurrentModal && !openPaymentRecurrentModal && (
              <>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  {billing?.recurrencePayment ? (
                    <div className='container mt-5'>
                      <div className='row p-5' style={{backgroundColor: 'rgb(249 234 233)'}}>
                        <div className='col-2'>
                          <KTIcon iconName='cross' className='fs-1' />
                        </div>

                        <div className='col-5 fw-bold' hidden={!billing?.recurrencePayment}>
                          {billing?.paymentOption.category.name}
                          <div className='col-4 mb-2 mt-2'>
                            {billing?.recurrencePayment?.creditCard?.holderName}{' '}
                          </div>

                          <div className='col-4 mb-2'>
                            ****
                            {billing?.recurrencePayment?.creditCard?.lastNumbers}{' '}
                          </div>
                          <div className='col-4 mb-2'>
                            {cpfMask(billing?.recurrencePayment?.creditCard?.holderDocument)}{' '}
                          </div>

                          <div className='col-4 mb-2'>
                            {billing?.recurrencePayment?.creditCard?.expirationMonth} /{' '}
                            {billing?.recurrencePayment?.creditCard?.expirationYear}
                          </div>
                        </div>

                        <div className='col-1'></div>
                        <div className='row col'>
                          <button className='btn btn-danger' onClick={() => sendLinkChangeCart()}>
                            Alterar Cartão
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className='row mb-6 mt-4'>
                    <div className='table-responsive'>
                      <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-120px'>Preço</th>
                            <th className='min-w-120px'>Dt de vencimento</th>
                            <th className='min-w-120px'>Parcela</th>
                            <th className='min-w-120px'>Status</th>
                            <th className='min-w-120px'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {billing?.recurrencePayment ? (
                            <TbodyRecurrent
                              billing={billing}
                              verifyAction={veriifyAction}
                              options={options}
                            />
                          ) : (
                            <TbodyNotRecurrent
                              billing={billing}
                              verifyAction={veriifyAction}
                              options={options}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
            {openPaymentRecurrentModal && !openDetailModal && !openChangeRecurrentModal && (
              <PaymentRecurrentModal
                billingInstallment={dataPaymentRecurrent}
                billing={billing}
                closeModal={() => closeModals()}
              />
            )}

            {openChangeRecurrentModal && !openDetailModal && !openPaymentRecurrentModal && (
              <ChangeRecurrentModal
                billingInstallment={dataChangeRecurrent}
                billing={billing}
                closeModal={() => closeModals()}
              />
            )}
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const TbodyNotRecurrent: React.FC<ITbodyProps> = ({billing, verifyAction, options}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  return (
    <>
      {billing?.installments.map((installment, index) => (
        <tr key={index}>
          <td>
            <b>{formatMask(installment.amount)}</b>
          </td>
          <td>
            <b>{formatDate(installment.dueDate)}</b>
          </td>
          <td>
            <b>{installment.installment}</b>
          </td>
          <td>
            <span
              className={
                installment.status === 'Pendente' ? 'badge badge-warning' : 'badge badge-success'
              }
            >
              {installment.i18nStatus ?? installment.status}
            </span>
          </td>
        </tr>
      ))}
    </>
  )
}
const TbodyRecurrent: React.FC<ITbodyProps> = ({billing, verifyAction, options}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      {billing?.recurrencePayment?.installments?.map((installment, index) => (
        <tr key={index}>
          <td>
            <b>{formatMask(installment.amount)}</b>
          </td>
          <td>
            <b>{formatDate(installment.dueDate)}</b>
          </td>
          <td>
            <b>{installment.installmentsNumber}</b>
          </td>
          <td>
            <span
              className={
                installment.status === 'pending' ? 'badge badge-warning' : 'badge badge-success'
              }
            >
              {installment.i18nStatus}
            </span>
          </td>
          <td className='col-4 text-center' hidden={installment.status !== 'pending'}>
            <div className='col-12'>
              <a
                href='#'
                className='btn btn-sm btn-flex-center btn-info btn-center menu-dropdown col-12'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                style={{
                  // backgroundColor: '#219123',
                  color: 'white',
                  borderRadius: '7px',
                  whiteSpace: 'nowrap',
                }}
              >
                <span className='text-center'>Ações</span>
                <i className='ki-duotone ki-down fs-5 ms-1'></i>
              </a>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-auto py-2'
                data-kt-menu='true'
                data-popper-placement='bottom-end'
              >
                <div className='menu-item px-3'>
                  {options.map((option) => (
                    <span
                      key={option.value}
                      className='menu-link px-3'
                      onClick={() => verifyAction(option.value, installment)}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {option.label}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </>
  )
}

export {BillingDetailModal}
